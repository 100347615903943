const getIsPromoDate = () => {
  const currentDate = new Date();
  const startDate = new Date('2024-12-19');
  const endDate = new Date('2025-01-01');

  return currentDate >= startDate && currentDate <= endDate;
};

export default {
  getIsPromoDate,
};
